import dynamic from 'next/dynamic';
import cn from 'classnames';

const Icon = dynamic(() => import('components/Icon'));
const Loader = dynamic(() => import('components/Loader/Spinner'));

export type ButtonSize = 'small' | 'medium' | 'large';

export type ButtonType = 'primary' | 'dark' | 'secondary' | 'text' | 'primary-blue';
export interface ButtonProps
  extends Omit<Omit<React.ButtonHTMLAttributes<HTMLDivElement>, 'type'>, 'prefix'> {
  onClick?: () => void;
  prefix?: React.ReactNode;
  loading?: boolean;
  submit?: boolean;
  buttonClass?: React.CSSProperties;
  form?: string;
  icon?: any;
  block?: boolean;
  round?: boolean;
  size?: ButtonSize;
  type?: ButtonType;
  currentSlide?: string;
  slideCount?: string;
}

const Button: React.FC<ButtonProps> = ({
  submit = false,
  onClick,
  prefix,
  className,
  buttonClass,
  icon,
  loading,
  block,
  round,
  size = 'medium',
  type = 'text',
  currentSlide,
  slideCount,
  children,
  disabled,
  style,
  ...props
}) => (
  <div
    onClick={!disabled ? onClick : () => {}}
    style={{ ...(block && { height: '100%' }), ...style }}
    {...props}
  >
    <button
      type={submit ? 'submit' : 'button'}
      className={cn(
        `button`,
        `button--${size}`,
        `button--${disabled ? 'disabled' : type}`,
        {
          'button--round': round,
          'button--icon': icon,
        },
        className,
      )}
      style={{ ...(block && { height: '100%' }), ...buttonClass }}
    >
      {loading && <Loader size="small" />}
      {icon ? <Icon component={typeof icon !== 'string' ? icon : undefined} /> : null}
      {children}
    </button>
  </div>
);

export default Button;
